import type {RouterConfig} from '@nuxt/schema';
import authorizeBeforeEnter from '~/app/authorize-before-enter';

export const enum RoutesNamesEnum {
  Home = 'home',
  PasswordRecovery = 'passwordRecovery',
  LoginFailed = 'loginFailed',
  AuthenticationFailed = 'authenticationFailed',
  Profile = 'profile',
  ProfileSettings = 'profileSetting',
  ProfileHistory = 'profileHistory',
  ProfileSecurity = 'profileSecurity',
  CreateAssignment = 'createAssignment',
  Assignments = 'assignments',
  Assignment = 'assignment',
  ServiceProviderUserProfile = 'serviceProviderUserProfile',
  ClientsUserProfile = 'clientsUserProfile',
  Categories = 'categories',
  Legal = 'legal',
  Faq = 'faq',
}

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: _routes => [
    {
      name: RoutesNamesEnum.Home,
      path: '/',
      component: () => import('~/pages/index.vue').then(r => r.default || r),
    },
    {
      name: RoutesNamesEnum.PasswordRecovery,
      path: '/password-recovery',
      component: () => import('~/pages/index.vue').then(r => r.default || r),
    },
    {
      name: RoutesNamesEnum.LoginFailed,
      path: '/login-failed',
      component: () =>
        import('~/pages/loginFailed.vue').then(r => r.default || r),
    },
    {
      name: RoutesNamesEnum.AuthenticationFailed,
      path: '/authentication-failed',
      component: () => import('~/pages/index.vue').then(r => r.default || r),
    },
    {
      name: RoutesNamesEnum.Profile,
      path: '/profile',
      component: () => import('~/pages/Profile').then(r => r.default || r),
      meta: {
        layout: 'profile-layout',
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.ProfileSettings,
      path: '/profile-settings',
      component: () =>
        import('~/pages/Profile/Settings').then(r => r.default || r),
      meta: {
        layout: 'profile-layout',
        middleware: ['account-settings-dto'],
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.ProfileHistory,
      path: '/profile-history',
      component: () =>
        import('~/pages/Profile/AssignmentHistory').then(r => r.default || r),
      meta: {
        layout: 'profile-layout',
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.ProfileSecurity,
      path: '/profile-security',
      component: () =>
        import('~/pages/Profile/Security').then(r => r.default || r),
      meta: {
        layout: 'profile-layout',
        middleware: ['account-security-dto'],
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.CreateAssignment,
      path: '/create-assignment',
      component: () =>
        import('~/pages/CreateAssignment').then(r => r.default || r),
      meta: {
        layout: 'assignment-layout',
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.Assignments,
      path: '/assignments',
      component: () => import('~/pages/Assignments').then(r => r.default || r),
      meta: {
        middleware: ['assignments-list-dto'],
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.Assignment,
      path: '/assignments/:id',
      component: () => import('../pages/Assignment').then(r => r.default || r),
      meta: {
        layout: 'assignment-layout',
        middleware: ['assignment-dto', 'assignment-engagement-list-dto'],
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.ServiceProviderUserProfile,
      path: '/service-providers/:id',
      component: () => import('~/pages/User').then(r => r.default || r),
      meta: {
        middleware: ['user-dto'],
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.ClientsUserProfile,
      path: '/users/:id',
      component: () => import('~/pages/User').then(r => r.default || r),
      meta: {
        middleware: ['user-dto'],
      },
      beforeEnter: authorizeBeforeEnter,
    },
    {
      name: RoutesNamesEnum.Categories,
      path: '/categories',
      component: () => import('~/pages/Categories').then(r => r.default || r),
    },
    {
      name: RoutesNamesEnum.Legal,
      path: '/legal',
      component: () => import('~/pages/PublicOffer').then(r => r.default || r),
    },
    {
      name: RoutesNamesEnum.Faq,
      path: '/faq',
      component: () => import('~/pages/FAQ').then(r => r.default || r),
    },
  ],
};

<template>
  <div class="registration-step4">
    <div class="modal-content">
      <div class="registration-step4-img">
        <img src="@/assets/img/executorbanner.png" />
      </div>

      <div class="registration-step4-title mt-6 mb-8">
        Ваш пароль успешно изменен
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const passwordRecoveryStore = usePasswordRecoveryStore();

  onUnmounted(() => {
    passwordRecoveryStore.$reset();
  });
</script>

<style lang="scss" scoped>
  .modal-content {
    padding: 3.25rem 5rem;

    @media screen and (width <= 728px) {
      padding: 2rem 1rem 0;
    }
  }

  .registration-step4 {
    &-img {
      width: 90px;
      height: 75px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      font-size: 28px;
      font-weight: 500;
      text-align: center;
    }
  }
</style>

import validate from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/usr/src/nuxt-app/middleware/01.auth.global.ts";
import _02_45categories_45dto_45global from "/usr/src/nuxt-app/middleware/02.categories-dto.global.ts";
import locations_45dto_45global from "/usr/src/nuxt-app/middleware/locations-dto.global.ts";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45categories_45dto_45global,
  locations_45dto_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-security-dto": () => import("/usr/src/nuxt-app/middleware/account-security-dto.ts"),
  "account-settings-dto": () => import("/usr/src/nuxt-app/middleware/account-settings-dto.ts"),
  "assignment-dto": () => import("/usr/src/nuxt-app/middleware/assignment-dto.ts"),
  "assignment-engagement-list-dto": () => import("/usr/src/nuxt-app/middleware/assignment-engagement-list-dto.ts"),
  "assignments-list-dto": () => import("/usr/src/nuxt-app/middleware/assignments-list-dto.ts"),
  "general-reviews-dto": () => import("/usr/src/nuxt-app/middleware/general-reviews-dto.ts"),
  "user-dto": () => import("/usr/src/nuxt-app/middleware/user-dto.ts")
}
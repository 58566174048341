import type {
  DynamicDialogInstance,
  DynamicDialogOptions,
} from 'primevue/dynamicdialogoptions';

export const useDialogStore = defineStore('dialogStore', () => {
  const route = useRoute();
  const dialog = useDialog();
  let dialogRef: DynamicDialogInstance | undefined;

  watch(
    () => route.path,
    () => {
      closeDialog();
    },
  );

  function getDialogRef(): DynamicDialogInstance | undefined {
    return (inject('dialogRef') as {value: DynamicDialogInstance} | undefined)
      ?.value;
  }

  function getDialogData(): {[x: string]: unknown} | undefined {
    return getDialogRef()?.data;
  }

  function closeDialog() {
    dialogRef?.close();
  }

  function openDialog(
    content: unknown,
    options: DynamicDialogOptions = {},
    header?: string,
  ) {
    closeDialog();

    dialog.open(content, {
      ...options,
      props: {
        modal: true,
        draggable: false,
        ...options.props,
        header,
      },
    });
  }

  function setDialogRef(ref: DynamicDialogInstance) {
    dialogRef = ref;
  }

  function $reset() {
    dialogRef = undefined;
  }

  return {
    getDialogRef,
    openDialog,
    closeDialog,
    getDialogData,
    setDialogRef,
    $reset,
  };
});

<template>
  <div class="ui-switcher-type">
    <div
      :class="['ui-switcher-type-btn', {'btn-orange': switcher === 'Client'}]"
      @click="setModel('Client')"
    >
      Я заказчик
    </div>
    <div
      :class="[
        'ui-switcher-type-btn',
        {'btn-orange': switcher === 'ServiceProvider'},
      ]"
      @click="setModel('ServiceProvider')"
    >
      Я исполнитель
    </div>
  </div>
</template>

<script setup lang="ts">
  import type {AccountRole} from '~/stores/accountProfile';

  const {initialValue} = defineProps<{
    initialValue?: AccountRole;
  }>();

  // TODO: refactor default account type
  const switcher = ref<AccountRole>(initialValue || 'Client');
  const emit = defineEmits(['update:modelValue']);

  function setModel(value: AccountRole) {
    switcher.value = value;

    emit('update:modelValue', value);
  }
</script>

<style lang="scss" scoped>
  @import 'assets/styles/variables';

  .ui-switcher-type {
    display: flex;
    align-items: center;
    height: 49px;
    width: 279px;
    box-shadow: 0 38px 202px 0 #1e272f57;
    border-radius: $border-radius-medium;
    white-space: nowrap;

    .btn-orange {
      padding: 0 21px;
      color: #fff;
      height: 100%;

      &:hover {
        opacity: 1;
      }
    }

    &-btn {
      padding: 0 21px;
      color: #1e272f82;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>

<template>
  <p class="flex flex-items-center flex-justify-center password-complexity">
    Сложность вашего пароля:

    <span
      :class="[
        {weak: !isMedium && !isStrong},
        {medium: isMedium && !isStrong},
        {strong: isStrong},
      ]"
    >
      <IconShield class="ml-1 mr-1" />
      <span v-if="isStrong">Высокая</span>
      <span v-else-if="isMedium">Средняя</span>
      <span v-else>Низкая</span>
    </span>
  </p>
</template>

<script setup lang="ts">
  import {IconShield} from '#components';

  const mediumRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])?(?=.*\W)?(?!.* ).{6,}$/;

  const strongRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;

  const props = defineProps({
    password: {default: '', type: String},
  });

  const isMedium = ref(false);
  const isStrong = ref(false);

  onMounted(() => {
    testPassword(props.password);
  });

  watch(
    () => props.password,
    () => {
      testPassword(props.password);
    },
  );

  function testPassword(pass: string) {
    isMedium.value = mediumRegex.test(pass);
    isStrong.value = strongRegex.test(pass);
  }
</script>

<style lang="scss" scoped>
  @import 'themes/mytheme/variables';

  .password-complexity {
    font-size: 14px;
    color: rgb(30 39 47 / 51%);
  }

  .medium {
    color: $warningColor;
  }

  .weak {
    color: $errorColor !important;
  }

  .strong {
    color: $successColor;
  }
</style>

<template>
  <div class="auth-modal">
    <component
      :is="passwordRecoveryStepToComponentMap[currentStep]"
      @next-step="nextStep()"
      @back-step="backStep()"
    />
  </div>
</template>

<script setup lang="ts">
  import {
    ModalPasswordRecoveryStep1,
    ModalPasswordRecoveryStep2,
    ModalPasswordRecoveryStep3,
    ModalPasswordRecoveryStep4,
  } from '#components';
  import type {DynamicDialogInstance} from 'primevue/dynamicdialogoptions';
  import type {Component} from 'vue';
  import {PasswordRecoveryStepEnum} from '~/stores/passwordRecovery';

  const passwordRecoveryStepToHeaderMap: Partial<
    Record<PasswordRecoveryStepEnum, string>
  > = {
    [PasswordRecoveryStepEnum.Start]: 'Восстановление пароля',
    [PasswordRecoveryStepEnum.Confirm]: 'Новый пароль',
  };

  const passwordRecoveryStepToComponentMap: Record<
    PasswordRecoveryStepEnum,
    Component
  > = {
    [PasswordRecoveryStepEnum.Start]: ModalPasswordRecoveryStep1,
    [PasswordRecoveryStepEnum.SendEmail]: ModalPasswordRecoveryStep2,
    [PasswordRecoveryStepEnum.Confirm]: ModalPasswordRecoveryStep3,
    [PasswordRecoveryStepEnum.Complete]: ModalPasswordRecoveryStep4,
  };

  const passwordRecoveryStore = usePasswordRecoveryStore();
  const {setRegistrationStep} = passwordRecoveryStore;
  const {currentStep} = storeToRefs(passwordRecoveryStore);

  const dialogRef = inject('dialogRef') as {value: DynamicDialogInstance};

  onMounted(() => {
    dialogRef.value.options.props!.header =
      passwordRecoveryStepToHeaderMap[currentStep.value];
  });

  function nextStep() {
    currentStep.value++;

    dialogRef.value.options.props!.header =
      passwordRecoveryStepToHeaderMap[currentStep.value];

    setRegistrationStep(currentStep.value);
  }

  function backStep() {
    currentStep.value--;

    dialogRef.value.options.props!.header =
      passwordRecoveryStepToHeaderMap[currentStep.value];

    setRegistrationStep(currentStep.value);
  }
</script>

<style lang="scss" scoped></style>

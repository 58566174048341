import {type AccountGeneralInfo} from '~/stores/accountProfile';

export default defineNuxtRouteMiddleware(async _ => {
  const {accountGeneralInfo, fetchAccountAuthInfo, setAccountGeneralInfo} =
    useAccountProfileStore();

  if (!accountGeneralInfo) {
    const {data} = await fetchAccountAuthInfo();

    setAccountGeneralInfo(data.value as unknown as AccountGeneralInfo);
  }
});
